<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
      <div class="card card-body">
        <div class="col-12 table-responsive" style="min-height: 10px">
          <table class="table table-hover table-bordered">
            <thead>
              <th>عدد المسجلين من خلالك</th>
              <th>{{ stats.users }} مستخدم</th>
            </thead>
            <thead>
              <th>اجمالي الرصيد (المسحوب والقابل للسحب)</th>
              <th>{{ stats.total }} ريال</th>
            </thead>
            <thead>
              <th>الرصيد القابل للسحب</th>
              <th>{{ stats.withdrawal }} ريال</th>
            </thead>
          </table>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-primary" v-b-modal.modal-1>
            سحب الرصيد ({{ stats.withdrawal }} ريال) او الاشتراك في النظام مع
            خصم 50%
          </button>
        </div>
      </div>
    </div>
    <div class="col-12"></div>
    <div class="col-12 col-lg-8 c g">
      <div class="card card-body">
        <p>عمليات الاشتراك</p>
        <div class="col-12 table-responsive" style="min-height: 10px">
          <table class="table table-hover table-bordered">
            <thead>
              <th>رقم الطلب</th>
              <th>المستحق</th>
              <th>التاريخ</th>
            </thead>
            <tbody>
              <tr v-for="order in stats.orders" :key="order._id">
                <td>
                  {{ order.order_id }}
                  <span v-if="order.calculated" class="btn btn-sm btn-success">
                    <i class="fa fa-check"></i>
                    تمت المحاسبة عليه
                  </span>
                </td>
                <td>{{ order.amount }} ريال</td>
                <td>
                  {{ order.date }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-8 c g">
      <div class="card card-body">
        <p>عمليات سحب الرصيد</p>
        <div class="col-12 table-responsive" style="min-height: 10px">
          <table class="table table-hover table-bordered">
            <thead>
              <th>التاريخ</th>
              <th>المبلغ</th>
              <th>الحالة</th>
              <th>التفاصيل</th>
            </thead>
            <tbody>
              <tr v-for="order in stats.withdraw_orders" :key="order._id">
                <td>
                  {{ order.date }}
                </td>
                <td>{{ order.amount }} ريال</td>
                <td>
                  <span
                    v-if="order.status == 0"
                    style="border-radius: 0px"
                    class="btn btn-sm btn-warning"
                    >في الانتظار</span
                  >
                  <span
                    v-if="order.status == 1"
                    style="border-radius: 0px"
                    class="btn btn-sm btn-success"
                    >تم بنجاح</span
                  >
                  <span
                    v-if="order.status == 2"
                    style="border-radius: 0px"
                    class="btn btn-sm btn-danger"
                    >مرفوض</span
                  >
                </td>
                <td>
                  {{ order.details }}
                  <span
                    v-if="order.admin_details && order.admin_details != ''"
                    class="text-danger"
                  >
                    <br />
                    {{ order.admin_details }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" hide-footer>
      <div v-if="stats.withdrawal >= 50">
        <div class="form-group">
          <h5 for="">هل تريد سحب الرصيد ام الاشتراك من خلاله مع خصم 50%؟</h5>
          <select class="form-control" v-model="type">
            <option value="subscribe">اشتراك مع خصم 50%</option>
            <option value="withdraw">تحول المبلغ على حسابي</option>
          </select>
        </div>
        <div class="form-group" v-if="type == 'subscribe'">
          <label for="">اختر الخطة المراد الاشتراك بها</label>
          <select class="form-control" v-model="plan">
            <option value="2">الخطة الفضية (50 ريال بدلاً من 100 ريال)</option>
            <option value="3">الخطة الذهبية (125 ريال بدلاً من 250)</option>
          </select>
        </div>
        <div class="form-group" v-if="type == 'subscribe'">
          <h5 for="">هل تود اضافة اي ملاحظات؟</h5>
          <textarea class="form-control" v-model="details"></textarea>
        </div>
        <div class="form-group" v-if="type != 'subscribe'">
          <h5 for="">
            برجاء كتابة رقم حسابك البنكي واسم المستفيد واسم البنك واي ملاحظات
            تود اضافتها
          </h5>
          <textarea
            class="form-control"
            placeholder="اكتب هنا..."
            rows="5"
            v-model="details"
          ></textarea>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-success" @click="sendOrder()">
            ارسال الطلب
          </button>
        </div>
      </div>
      <div v-if="stats.withdrawal < 50">
        <div class="alert bg-warning text-center g">
          الحد الادنى للسحب او للاشتراك هو 50 ريال، والمبلغ الحالي
          {{ stats.withdrawal }} ريال.
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "subscribe",
      plan: "2",
      details: "",
      user: JSON.parse(localStorage.getItem("user")),
      stats: {
        users: 0,
        total: 0,
        withdrawal: 0,
        orders: [],
        withdraw_orders: [],
      },
    };
  },
  created() {
    var g = this;
    $.post(api + "/user/reseller/stats", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        g.stats = r.response;
      })
      .catch(function () {
        alert("حدثت مشكلة في الاتصال");
      });
  },
  methods: {
    sendOrder() {
      var g = this;
      $.post(api + "/user/reseller/send", {
        jwt: this.user.jwt,
        type: g.type,
        plan: g.plan,
        details: g.details,
      })
        .then(function (r) {
          if (r.status == 100) {
            location.reload();
          } else {
            alert(r.response);
          }
        })
        .catch(function () {
          alert("حدثت مشكلة في الاتصال");
        });
    },
  },
};
</script>

<style>
</style>